export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const BUSINESS_EMAIL_REGEX = /^((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))+@(?!qq.com)(?!yahoo.com)(?!gmail.com)(?!ymail.com)(?!hotmail.com)(?!yahoo.co.uk)(?!yahoo.fr)(?!yahoo.co.in)(?!aol.com)(?!abc.com)(?!xyz.com)(?!pqr.com)(?!rediffmail.com)(?!live.com)(?!outlook.com)(?!me.com)(?!msn.com)([\w-]+\.)+[\w-]{2,6})?$/i
export const NEW_BUSINESS_EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@(?!yahoo\.|gmail\.|hotmail\.|abc\.|xyz\.|live\.|outlook\.|guerrillamail\.|10minutemail\.|tempmail\.|mailinator\.|dispostable\.|getnada\.|fakeinbox\.|mohmal\.|maildrop\.|mytemp\.|mailcatch\.|yopmail\.|mailnesia\.|sharklasers\.|mailmetrash\.|discard\.|fake-mail\.|mintemail\.|mailnesia\.|binkmail\.|mailsucker\.|tempemail\.|mailinator2\.|throwawaymail\.|inboxbear\.|tempmailo\.|trashmail\.|mailnesia\.|moakt\.|notsharingmy\.|spam4\.|spamhole\.|anonaddy\.)(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/i
export default {
  methods: {
    checkEmail (email) {
      return EMAIL_REGEX.test(email)
    },
    checkBusinessEmail (value) {
      const email = value ? value.toLowerCase() : null
      return EMAIL_REGEX.test(email)
    },
    checkBusinessEmailV2 (value) {
      const email = value ? value.toLowerCase() : null
      return NEW_BUSINESS_EMAIL_REGEX.test(email)
    }
  }
}
